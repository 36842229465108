@import "src/styles/variables";

.wrapper {
  display: inline-block;
  text-decoration: none;
  color: var(--color-D100);

  &::after {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    border: 1px solid currentColor;
    border-left: none;
    border-bottom: none;
    margin: 0 15px 0 10px;
    transform: rotate(45deg) translateY(-2px);
  }

  &:last-child {
    &::after {
      display: none;
    }
  }

  &[href] {
    :hover {
      color: var(--color-B100);
    }
  }
}
